.trackcontainer {
	margin: 5rem;
}

.box {
	background-color: white;
	padding: 1rem 0;
}

.box h3 span {
	color: #ffc94a;
}

.activeClass {
	background-color: #e5e7eb;
	/* Equivalent to bg-gray-200 in Tailwind */
	cursor: pointer;
	font-size: 0.6rem;
	/* Equivalent to text-[.6rem] */
	padding: 0.5rem 0.5rem;
	/* Equivalent to px-2 py-2 */
	border-radius: 0.375rem;
	/* Equivalent to rounded-md */

	/* Styles for larger screens (lg) */
	font-size: 1rem;
	/* Equivalent to lg:text-[1rem] */
	padding: 0.75rem 1.5rem;
	/* Equivalent to lg:py-3 lg:px-6 */
	border-radius: 0.75rem;
	/* Equivalent to lg:rounded-xl */
}

/* Inactive Class */
.inActiveClass {
	cursor: pointer;
	font-size: 0.6rem;
	/* Equivalent to text-[.6rem] */
	padding: 0.5rem 0.5rem;
	/* Equivalent to px-2 py-2 */
	border-radius: 0.375rem;
	/* Equivalent to rounded-md */

	/* Styles for larger screens (lg) */
	font-size: 1rem;
	/* Equivalent to lg:text-[1rem] */
	padding: 0.75rem 1.5rem;
	/* Equivalent to lg:py-3 lg:px-6 */
	border-radius: 0.75rem;
	/* Equivalent to lg:rounded-xl */
}


.title {
	padding: 0 1.5rem;
	font-family: 'GilroyBold', sans-serif;
	font-size: 1.2rem;
	padding: 0.5rem 0;
}

.status-bar {
	background-color: #f3f4f6;
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	border-top: 1px solid #e5e7eb;
	border-bottom: 1px solid #e5e7eb;
}

.status-bars {
	background-color: #f3f4f6;
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	border-top: 1px solid #e5e7eb;
	border-bottom: 1px solid #e5e7eb;
}

.status-barss {
	background-color: #f3f4f6;
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	border-top: 1px solid #e5e7eb;
	border-bottom: 1px solid #e5e7eb;
}

.status-info {
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.status-infos {
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.status-infoss {
	padding: 0.75rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}

.status-text {
	color: #16a34a;
	/* Tailwind green-600 */
	text-transform: capitalize;
}

.tab {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.call-to-contact-area {
	padding-top: 100px;
}

.cards {
	margin-top: 240px;
}

.home {
	padding-top: 150px;
}

@media screen and (max-width: 460px) {
	.call-to-contact-area {
		padding-top: 0px;
	}

	.cards {
		margin-top: 0px;
	}

	.home {
		padding-top: 0px;
	}

	.box h3 {
		font-size: 1.5rem;
	}

	.status-barss {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 20px;
	}

	.status-bar {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
	}

	.status-bars {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
	}

	.status-infoss {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 20px;
	}

	.status-infos {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
	}

	.status-info {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
	}

	.trackcontainer {
		margin: 0;
		padding: 1rem;
	}
}