:root {
  --main-color: #ffc94a;
  --main-color-2: #181a2a;
  --active-color: #ffc94a;
  --bg-color: #f5f5f5;
  --heading-color: #080c24;
  --paragraph-color: #565969;
  --button-color: #14448c;
  --secondary-color: #ffffff;
  --body-font: "Public Sans", sans-serif;
  --heading-font: "Yantramanav", sans-serif;
  --body-font-size: 16px;
  --line-height30: 1.7;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  font-family: var(--body-font);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  margin: 0;
  color: var(--paragraph-color);
  overflow-x: hidden;
  font-family: var(--body-font);
  line-height: var(--line-height30);
  font-size: var(--body-font-size);
}

h1 {
  font-size: 84px;
  line-height: 90px;
}

h2 {
  font-size: 40px;
  line-height: 1.3380952380952381;
}

h3 {
  font-size: 36px;
  line-height: 1.3833333333333333;
}

h4 {
  font-size: 30px;
  line-height: 1.3380952380952381;
}

h5 {
  font-size: 24px;
  line-height: 1.3380952380952381;
}

h6 {
  font-size: 20px;
  line-height: 1.2380952380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 700;
  font-family: var(--heading-font);
}

p {
  color: var(--paragraph-color);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover {
  color: var(--main-color);
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li {
  list-style: none;
  margin-bottom: 1rem;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-gutter>[class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.no-gutter[class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.h-100vh {
  height: 100vh;
}

code {
  color: #faa603;
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: block;
    padding-left: 20px;
    position: relative;
    z-index: 0;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      font-family: "fontawesome";
      content: "\f105";
      color: var(--main-color);
    }
  }
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
  clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.comment-navigation .nav-previous>a,
.posts-navigation .nav-previous>a,
.post-navigation .nav-previous>a,
.comment-navigation .nav-next>a,
.posts-navigation .nav-next>a,
.post-navigation .nav-next>a {
  transition: 0.3s ease-in;
}

.comment-navigation .nav-previous:hover>a,
.posts-navigation .nav-previous:hover>a,
.post-navigation .nav-previous:hover>a,
.comment-navigation .nav-next:hover>a,
.posts-navigation .nav-next:hover>a,
.post-navigation .nav-next:hover>a {
  color: var(--main-color);
}

.comment-list li {
  list-style: none;
}

.h-100vh {
  height: 100vh;
}

.position-relative {
  position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/

/* Text meant only for screen readers. */

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 15px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */

#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/

.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/

.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */

embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */

.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  clear: both;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/

.wp-link-pages a {
  margin: 0 5px;
  transition: 0.3s ease-in;
}

.wp-link-pages {
  margin-bottom: 30px;
  margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
  background-color: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
}

.wp-link-pages span:first-child {
  margin-right: 5px;
}

dl,
ol,
ul {
  padding-left: 15px;
}

.post-password-form input {
  display: block;
  border: 1px solid #e2e2e2;
  height: 50px;
  border-radius: 3px;
  padding: 0 20px;
}

.post-password-form label {
  font-weight: 600;
  color: #333;
}

.post-password-form input[type="submit"] {
  width: 100px;
  height: 50px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.post-password-form input[type="submit"]:hover {
  background-color: #121a2f;
}

.footer-widget .table td,
.footer-widget .table th {
  padding: 0.5rem !important;
}

@media all and (min-width: 1200px) {

  .no-lg-gutters>.col,
  .no-lg-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}